body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  margin: 0px;
  margin-right: 60px;
}
#container {
  display: flex;
  flex-direction: column;
}
#inputContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
}
#calendarContainer {
  order: 0;
  display: flex;
  flex-direction: column;
  padding: 20px;
  flex: 1 1 200px;
}
#mapandlegend {
  display: flex;
  flex-direction: row;
  flex: 2;
  padding-left: 50px;
}
#legend {
  padding: 1em;
  order: 2;
  flex: 1;
  flex-basis: 250px;
}
#legend h1 {
  font-size: 1em;
}
#legend ul.legend {
  list-style-type: none;
  padding-left: 1em;
}
#who {
  margin-left: 1em;
}
#who h1 {
  font-size: 1em;
}
#map {
  order: 1;
  height: 520px;
  min-width: 680px;
  width: 680px;
  flex-basis: 680px;
}
#graph {
  width: 100%;
  padding-top: 20px;
  padding-left: 20px;
}
#info {
  font-size: x-small;
  text-align: center;
  text-anchor: middle;
}
#dateinfo {
  text-align: center;
  font-size: small;
}
strong.whowarning {
  color: red;
}